import Image from 'next/image';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack, Typography } from '@mui/material';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { getFirstImage } from '@qb/frontend/utils/getFirstImage';
import { PartRelevantPartsDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
type StockFoundProps = {
  stock: number;
};
const StockFound = ({
  stock
}: StockFoundProps) => <Stack alignItems="flex-end" spacing={1} data-sentry-element="Stack" data-sentry-component="StockFound" data-sentry-source-file="PartCardHorizontalInner.tsx">
    <Stack direction="row" spacing={0.5} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
      <CheckCircleIcon sx={{
      fontSize: '14px'
    }} color="success" data-sentry-element="CheckCircleIcon" data-sentry-source-file="PartCardHorizontalInner.tsx" />
      <Typography variant="bodyMediumPrimary" color="text.primary" data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
        STOCK: {stock}
      </Typography>
    </Stack>
  </Stack>;
const QuickQuote = () => <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="end" data-sentry-element="Stack" data-sentry-component="QuickQuote" data-sentry-source-file="PartCardHorizontalInner.tsx">
    <CheckCircleIcon sx={{
    fontSize: '14px'
  }} color="info" data-sentry-element="CheckCircleIcon" data-sentry-source-file="PartCardHorizontalInner.tsx" />
    <Typography variant="bodyMediumPrimary" color="text.primary" align="center" data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
      Quick Quote
    </Typography>
  </Stack>;
type PartCardHorizontalInnerProps = {
  part: PartRelevantPartsDataResponse['parts'][0];
};
export const PartCardHorizontalInner = ({
  part
}: PartCardHorizontalInnerProps) => {
  const discountPercentage = getDiscountPercentage(part.originalPrice, part.price);
  const isDiscounted = !!discountPercentage;
  return <Stack direction="row" alignItems="center" spacing={2} sx={{
    py: 1,
    px: 1.5,
    minWidth: 200,
    width: '100%'
  }} data-sentry-element="Stack" data-sentry-component="PartCardHorizontalInner" data-sentry-source-file="PartCardHorizontalInner.tsx">
      <Image src={getFirstImage(part.images)} width={50} height={50} alt={part.partNumber} style={{
      objectFit: 'contain'
    }} data-sentry-element="Image" data-sentry-source-file="PartCardHorizontalInner.tsx" />
      <Stack direction="column" sx={{
      width: '100%',
      overflow: 'hidden'
    }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
        <Stack direction="row" spacing={1} justifyContent="space-between" mb={1} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
          <Typography variant="h6" sx={{
          mr: 'auto'
        }} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {part.partNumber}
          </Typography>
          <Stack direction="column" gap={0.5} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {!!part.price && !isDiscounted && <Typography variant="h6" color="primary">
                {formatCents(part.price)}
              </Typography>}
            {!!part.price && isDiscounted && <Typography variant="h6" color="primary" sx={{
            px: 1,
            bgcolor: 'accent.main',
            borderRadius: '50px'
          }}>
                {formatCents(part.price)}
              </Typography>}
            {!!part.originalPrice && isDiscounted && <Typography variant="bodySmall" align="center" sx={{
            fontWeight: 600,
            textDecoration: 'line-through'
          }}>
                {formatCents(part.originalPrice)}
              </Typography>}
          </Stack>
        </Stack>
        <Typography title={part.description} variant="bodyMedium" mb={1} sx={clampLinesSx(1)} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
          {part.description}
        </Typography>
        <Stack mt="auto" direction="row" justifyContent="space-between" data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
          <Typography variant="bodyMediumPrimary" color="primary.light" noWrap data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {part.manufacturer.name}
          </Typography>
          {part.onHandQty ? <StockFound stock={part.onHandQty} /> : <QuickQuote />}
        </Stack>
      </Stack>
    </Stack>;
};